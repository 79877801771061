import React from "react";
import PopStyle from "../../assets/styles/PopStyle"
import Logo from "../../assets/image/Logo.svg";
import loadable from '@loadable/component'

const LoadComponentCartCheckout = loadable(() => import('./Cart-Checkout'))
const LoadComponentSearch = loadable(() => import('./Search'))
const LoadComponentRegLogin = loadable(() => import('./RegLogin'))

const Pop = ({triggerPop, positionPop, openPop}) => {
    // console.log('pop >>>>>>>',openPop, positionPop)

    // const [isLogin, setIsLogin] = useState(getFromLocal('jwtTokenData') || false);
    return (
        <PopStyle className={`wrapPop anim ${openPop && 'open' }`}>
            <div className="container  pos">
                <div className="border-ver wrap">
                    <div className="header">
                        <div className="row row-0 row-section border-ver wrap-header-1">
                            <div className="col col-lg-auto">
                                <div onClick={()=>triggerPop()} className='wrapLogo d-flex align-items-center'>
                                    <img src={Logo} />
                                </div>
                            </div>
                            <div className="d-none d-lg-block col-lg"></div>
                            <div className="col-auto">
                                <div className="wrapHeaderIcon">
                                    <button onClick={() =>triggerPop()} className='btn btn1 btnIcon borderNone ' type="button" aria-labelledby="button-label">
                                        <span className="iconBG icon24 iconBG20"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='wrapData border-hor-bottom'>
                        { positionPop === 'search' ? (
                            <LoadComponentSearch triggerPop={triggerPop} />
                        ) : '' }

                        { positionPop === 'cart' ? (
                            <LoadComponentCartCheckout triggerPop={triggerPop} />
                        ) : '' }

                        { positionPop === 'user' ? (
                            <LoadComponentRegLogin triggerPop={triggerPop} />
                        ) : '' }

                    </div>
                </div>
            </div>

        </PopStyle>
    )
}
export default Pop;