// src/functions/sendDateN.js

const SendDateN = async (data) => {
    try {
        // console.log("Data being sent: ", data); // Лог даних, які передаються

        let url = "";
        if (data.type === 'checkout') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/CreateOrder`;
        } else if (data.type === 'login') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/Login`;
        } else if (data.type === 'register') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/Register`;
        } else if (data.type === 'CommentsForm') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/CreateComments`;
        } else if (data.type === 'nova') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/SearchNova`;
        } else if (data.type === 'getOrder') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/GetOrders`;
        } else if (data.type === 'formSend') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/SendWPData`;
        } else if (data.type === 'sendCode') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/CodeVerific`;
        } else if (data.type === 'resetPassword') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/ResetPassword`;
        } else if (data.type === 'VerificEmail') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/VerificEmail`;
        } else if (data.type === 'GetDataAccount') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/GetDataAccount`;
        } else if (data.type === 'coupon') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/Coupon`;
        } else if (data.type === 'Subscribers') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/Subscribers`;
        } else if (data.type === 'Wishlist') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/Wishlist`;
        } else if (data.type === 'getWishlist') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/GetWishlist`;
        } else if (data.type === 'subscribeEmail') {
            url = `${process.env.GATSBY_SERVERLESS_URL}/SubscribeEmail`;
        } else {
            throw new Error("Invalid data type");
        }

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // Конвертуємо у JSON
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        const responseText = await res.text();
        const responseData = JSON.parse(responseText);
        // console.log("Response data:", responseData);
        return responseData;

    } catch (error) {
        // console.error("Error sending data to Netlify Function: >>>>>>>>", data);
        // alert('От халепа... Сталася помилка, просимо вибачення ми вже знаємо про проблему й працюємо над нею.')
        throw error;
    }
};

export default SendDateN;