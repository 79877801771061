// cartFunctions.js
import { getFromLocal, saveToLocal } from "./functions";

export const removeCartItem = (id) => {
    const cart = getCart(); // отримуємо поточний кошик

    // Фільтруємо товар з заданим id
    const updatedCart = cart.filter((item) => String(item.id) !== String(id));
    // Зберігаємо оновлений кошик
    saveToLocal("cart", updatedCart);
    // Генеруємо подію для оновлення інших компонентів (наприклад, Header)
    if (typeof window !== 'undefined') {
        window.dispatchEvent(new Event("cartUpdated"));
    }
    return updatedCart;
};

export const addToCart = (cart, product) => {
    const updatedCart = [...cart];
    const existingProductIndex = updatedCart.findIndex(
        (item) => String(item.id) === String(product.id)
    );

    if (existingProductIndex !== -1) {
        // Збільшуємо step, якщо товар є
        const oldStep = updatedCart[existingProductIndex].step || 1;
        const newStep = product.step || 1;
        updatedCart[existingProductIndex].step = oldStep + newStep;
        saveToLocal("cart", updatedCart);
    } else {
        // Додаємо новий товар
        updatedCart.push({
            ...product,
            step: product.step || 1,
        });
        saveToLocal("cart", updatedCart);
    }

    return updatedCart;
};

export const getCart = () => {
    const cartData = getFromLocal("cart");
    return Array.isArray(cartData) ? cartData : [];
};

// Функція оновлення товару у кошику
export const updateCartItem = (updatedItem) => {
    // Зчитуємо поточний кошик
    const cart = getCart();
    // Знаходимо індекс товару за id
    const index = cart.findIndex((item) => String(item.id) === String(updatedItem.id));
    if (index !== -1) {
        cart[index] = updatedItem;
    } else {
        // Якщо товару ще немає у кошику — додаємо його
        cart.push(updatedItem);
    }
    // Зберігаємо оновлений кошик
    saveToLocal("cart", cart);
    // Генеруємо подію для оновлення інших компонентів (наприклад, Cart, Header)
    if (typeof window !== 'undefined') {
        window.dispatchEvent(new Event("cartUpdated"));
    }
    return cart;
};


//  Спершу очищує кошик, потім додає товари з масиву
export const clearAndAddToCart = (products) => {
    if (!Array.isArray(products)) {
        console.error("Помилка: products має бути масивом.");
        return [];
    }

    // Очищаємо кошик
    const updatedCart = [];

    // Додаємо товари з переданого масиву
    products.forEach((product) => {
        updatedCart.push({ ...product, step: product.step || 1 });
    });

    // Зберігаємо оновлений кошик у локальному сховищі
    saveToLocal("cart", updatedCart);

    // Генеруємо подію для оновлення інших компонентів
    if (typeof window !== 'undefined') {
        window.dispatchEvent(new Event("cartUpdated"));
    }

    return updatedCart;
};