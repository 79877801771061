import styled from "styled-components";
import star_0 from "../svg/stars-0.svg"
import star_5 from "../svg/stars-5.svg"

const wrapStar = styled.div`
    div {
        width: 7rem;
        height: 1.4rem;
        background-image: url(${star_0});
        background-size: auto 100%;
        span {
            display: block; 
            height: 1.4rem;
            background-image: url(${star_5});
            background-size: auto 100%;
        }
        margin-right: 0.8rem;
        
        &.s1 {
            height: 1.8rem;
            width: 9rem;
            span {
                height: 1.8rem;
            }
        }

        &.s2 {
            height: 2.4rem;
            width: 12rem;
            span {
                height: 2.4rem;
            }
        }
        
    }
`
export default wrapStar;