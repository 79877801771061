exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-test-js": () => import("./../../../src/pages/page-test.js" /* webpackChunkName: "component---src-pages-page-test-js" */),
  "component---src-pages-type-js": () => import("./../../../src/pages/type.js" /* webpackChunkName: "component---src-pages-type-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/BlogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-page-account-js": () => import("./../../../src/templates/PageAccount.js" /* webpackChunkName: "component---src-templates-page-account-js" */),
  "component---src-templates-page-constructor-js": () => import("./../../../src/templates/PageConstructor.js" /* webpackChunkName: "component---src-templates-page-constructor-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-order-received-js": () => import("./../../../src/templates/PageOrderReceived.js" /* webpackChunkName: "component---src-templates-page-order-received-js" */),
  "component---src-templates-page-person-js": () => import("./../../../src/templates/pagePerson.js" /* webpackChunkName: "component---src-templates-page-person-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/pagePost.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-page-product-js": () => import("./../../../src/templates/pageProduct.js" /* webpackChunkName: "component---src-templates-page-product-js" */),
  "component---src-templates-page-promotion-js": () => import("./../../../src/templates/pagePromotion.js" /* webpackChunkName: "component---src-templates-page-promotion-js" */),
  "component---src-templates-page-wishlist-js": () => import("./../../../src/templates/pageWishlist.js" /* webpackChunkName: "component---src-templates-page-wishlist-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/ProductCategory.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-series-js": () => import("./../../../src/templates/ProductSeries.js" /* webpackChunkName: "component---src-templates-product-series-js" */),
  "component---src-templates-product-tag-js": () => import("./../../../src/templates/ProductTag.js" /* webpackChunkName: "component---src-templates-product-tag-js" */),
  "component---src-templates-product-universe-js": () => import("./../../../src/templates/ProductUniverse.js" /* webpackChunkName: "component---src-templates-product-universe-js" */)
}

