// functions.js

/**
 * Повертає значення з масиву об'єктів, де об'єкти мають { key, value }.
 * Якщо елемент не знайдено, повертає null.
 *
 * @param {Array} metaDataArray - Масив метаданих
 * @param {string} searchKey - Ключ, який шукаємо
 * @returns {*} - Значення, або null
 */
export const getMetaValue = (metaDataArray, searchKey) => {
    if (!Array.isArray(metaDataArray)) {
        console.error("metaDataArray має бути масивом");
        return null;
    }
    const foundItem = metaDataArray.find(item => item.key === searchKey);
    return foundItem ? foundItem.value : null;
};

/**
 * Зберігає будь-яке значення (масив, об’єкт, рядок тощо) у LocalStorage.
 *
 * @param {string} key - Ключ для LocalStorage
 * @param {*} value - Дані для зберігання
 */
export const saveToLocal = (key, value) => {
    if (typeof window === "undefined") return;
    try {
        const json = JSON.stringify(value);
        localStorage.setItem(key, json);
    } catch (error) {
        console.error(`Помилка при збереженні у LocalStorage: ${error}`);
    }
};

/**
 * Зчитує дані з LocalStorage за заданим ключем.
 *
 * @param {string} key - Ключ LocalStorage
 * @returns {*} - Розпарсені дані, або null
 */
export const getFromLocal = (key) => {
    if (typeof window === "undefined") return null;
    try {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    } catch (error) {
        console.error(`Помилка при зчитуванні з LocalStorage: ${error}`);
        return null;
    }
};

/**
 * Очищає весь LocalStorage або конкретні ключі.
 * @param {Array<string>|string|null} keys - Масив ключів, один ключ або null (null очищує весь localStorage).
 */
export const clearLocalStorage = (keys = null) => {
    if (typeof window === "undefined") return;

    if (keys === null) {
        // Якщо ключі не передані, очищуємо весь localStorage
        localStorage.clear();
        // console.log("LocalStorage очищено повністю.");
    } else if (Array.isArray(keys)) {
        // Якщо переданий масив ключів, очищуємо тільки ці ключі
        keys.forEach((key) => {
            localStorage.removeItem(key);
            // console.log(`Ключ '${key}' видалено з LocalStorage.`);
        });
    } else if (typeof keys === "string") {
        // Якщо переданий один ключ
        localStorage.removeItem(keys);
        // console.log(`Ключ '${keys}' видалено з LocalStorage.`);
    } else {
        console.error("Невірний формат параметра keys. Очікується null, масив або рядок.");
    }
};

export const saveToken = (data) => {
    if (typeof window === "undefined") return;
    localStorage.setItem("jwtTokenDate", JSON.stringify(data));
};

/**
 * Повертає розпарсений об'єкт з LocalStorage за ключем "jwtTokenDate".
 * Якщо дані відсутні або виникла помилка при парсінгу, повертає null.
 *
 * @returns {Object|null} - Об'єкт з даними токену або null
 */
export const getToken = () => {
    if (typeof window === "undefined") return;
    const tokenData = localStorage.getItem("jwtTokenDate");
    if (tokenData) {
        try {
            const parsedData = JSON.parse(tokenData);
            return parsedData;
        } catch (error) {
            console.error("Помилка при парсінгу даних токену:", error);
            return null;
        }
    }
    return null;
};

export const clearToken = () => {
    localStorage.removeItem("jwtTokenDate");
};



export const funMassage = (title, text, go, btnName, type) => {
    if (typeof window !== 'undefined') {
        const popInform = document.getElementById('popInform');
        popInform.classList.add('active');
        setTimeout(() => {
            popInform.classList.remove('active');
        }, 6000);

        const popInformTitle = document.getElementById('popInformTitle');
        const popInformText = document.getElementById('popInformText');
        const popInformGo = document.getElementById('popInformGo');

        if ( go === '' ) {
            popInformGo.classList.remove('active');
        } else {
            popInformGo.classList.add('active');
        }

        // console.log('go', go)

        popInformTitle.innerHTML = title;
        popInformText.innerHTML = text;
        popInformGo.setAttribute('go', go);
        popInformGo.innerHTML = btnName;
        popInform.setAttribute('type', type);
    }
};



export const getMetaValueByKey = (metaDataArray, searchKey) => {
    const foundItem = metaDataArray.find(item => item.key === searchKey);
    return foundItem ? foundItem.value : null; // або можна повернути порожній рядок
}