import styled from 'styled-components'
import {maxCol} from "../../function/SizeCol";
import Icons24_25 from "../svg/24/Icons24px-25.svg"
import Icons24_34 from "../svg/24/Icons24px-34.svg"
import Icons24_35 from "../svg/24/Icons24px-35.svg"
import Icons24_36 from "../svg/24/Icons24px-36.svg"
import Icons24_37 from "../svg/24/Icons24px-37.svg"


const WrapHeader = styled.header`
    position: fixed;
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--paper);
    left: 0;
    right: 0;
    margin: auto;
    top: 1.8rem;
    transition: 200ms top;
    z-index: 90;

    &:before {
        background-color: var(--paper);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1000px;
        right: -1000px;
    }
    &:after {
        content: '';
        display: block;
        z-index: -1;
        background-color: var(--paper);
        position: absolute;
        left: -1000px;
        right: -1000px;
        bottom: 100%;
        height: 1.8rem;
    }
 
    .wrapLogo {
        padding-left: 8.3rem;
        padding-right: 8.3rem; 
        // @media (max-width:${maxCol.xxl}) {
        //     padding-left: 7.8rem;
        //     padding-right: 7.8rem;
        // }
        @media (max-width:${maxCol.xl}) { 
            padding-left: 4.2rem;
            padding-right: 4.2rem;
        }

        @media (max-width:${maxCol.sm}) {
            padding-left: 1rem;
            padding-right: 1rem;
            justify-content: center;
            img {
                max-width: 9rem;
                width: 100%;
            }
        }
    }
    .wrapLogo {
        height: 6.4rem;
        @media (max-width:${maxCol.xl}) {
            height: 5.6rem;
        }
    }
    
    .wrapHeaderIcon {
        width: 6.4rem;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:${maxCol.xl}) {
            width: 5.6rem;
        } 
    } 
    
    .cartCountBadge {
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background-color: var(--red);
        color: var(--paper);
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 2rem;
        left: 2rem;
        bottom: 0;
        right: 0;
        margin: auto;
        font-weight: 400;
    }

    .iconBG25:before  { background-image: url(${Icons24_25}); }
    .iconBG34:before  { background-image: url(${Icons24_34}); }
    .iconBG35:before  { background-image: url(${Icons24_35}); }
    .iconBG36:before  { background-image: url(${Icons24_36}); }
    .iconBG37:before  { background-image: url(${Icons24_37}); }
    

`;
export default WrapHeader;