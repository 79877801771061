import styled from "styled-components";
import {maxCol} from "../../function/SizeCol";
import Logo from '../../assets/image/Logo.svg'

const WrapPost = styled.div`
    .logo { 
        background-image: url(${Logo});
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center center;
        margin-bottom: 0;
        //background-repeat: no-repeat;
    }
    padding-left: 6.2rem;
    padding-right: 6.2rem;
    @media (max-width: ${maxCol.lg} ) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .itemPost {
        padding-bottom: 3.6rem;
        padding-top: 3.8rem;
        @media (max-width: ${maxCol.lg} ) {
            padding-top: 4.2rem;
            padding-bottom: 4rem;
        }
        @media (max-width: ${maxCol.sm} ) {
            &.border-hor-bottom:after {
                background-color: var(--slgray);
            }
        }
        
    }
    .WrapImg {
        margin-bottom: 2.4rem;
        //padding-top: 54.5%;
        position: relative;
        height: 22.1rem; 
        @media (max-width: ${maxCol.md}) {
            height: 19rem; 
        }
        background-color: var(--green);
        border: 2px solid var(--gray);
        display: block;
        overflow: hidden;  
        .img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 100%;
        }
    }
    .date {
        margin-right: 1.4rem;
        color: var(--lgray);
        @media (max-width: ${maxCol.sm} ) {
            font-size: 1.4rem;
        }
        
    }
    .PostTitle {
        margin-top: 1.2rem;
        display: block;
        text-decoration: none;
    }
    .PostCategory { 
        color: var(--blue);
        text-decoration: none;
        margin-right: 1rem;
        &:hover {
            color: var(--green);
        }
        @media (max-width: ${maxCol.sm} ) {
            font-size: 1.4rem;
        }
    }
    .row {
        --bs-gutter-x: 1.9rem;
    }
    
    @media (max-width: ${maxCol.sm} ) {
        .border-hor-bottom:after {
            left: 0;
            right: 0;
        }
        & > .row > .col:last-child .border-hor-bottom:after {
            left: -500px;
            right: -500px;
        }
    }
`
export default WrapPost;