// src/components/layout.js
import React from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import GlobalStyles from "../assets/styles/GlobalStyles"
import {graphql, Link, navigate, useStaticQuery} from "gatsby";
// import SmoothScroll from '../components/SmoothScroll';

const Layout = ({ children, props  }) => {

    // const data = useStaticQuery(graphql
    // query QueryGeneralSettings {
    //
    //
    // }
    //
    // );
    //
    // const d = data.wp.acfThemeGeneralSettings.aCFoptionTemplate;
    //
    // const {
    //     groupTemplateBook,
    //     groupTemplateCategory,
    //     groupTemplateDirectionsPersons,
    //     groupTemplatePage,
    //     groupTemplatePerson,
    //     groupTemplatePost,
    //     groupTemplateProductCat,
    //     groupTemplateProductTag
    // } = d;
    // console.log('>>>>>>>>>>>>>>>>>>>>>> option:', d )


    // const getData = props.pageContext === {} && true : false ;
    const getDataPageContext = props?.pageContext;
    const dataKey = props?.location.key;

    // console.log('getData >>>>>>>>>', dataKey, props)




    // console.log('is404 >>>>>>>>>', is404 , props)

    const templateName = props.pageContext?.templateFix || props.pageContext?.template?.templateName;

    // Функція для визначення класу шаблону сторінки
    const getTemplateClass = () => {
        if (Object.keys(getDataPageContext).length  === 0) {
            return "page404";
        } else {
            return templateName
        }
        // return templateName || "customPage";
    };

    const templateClass = getTemplateClass();


    const closePopInform = (op) => {
        if ( typeof document !== 'undefined' ) {
            if ( op === 'go' ) {
                // const button = typeof document !== 'undefined' && document.getElementById('showPop');
                const popInform = document.getElementById('popInform');
                popInform.classList.remove('active');

                const popInformGo = document.getElementById('popInformGo');
                const goAttributeValue = popInformGo.getAttribute('go');
                if ( goAttributeValue === 'cart' ) {
                    // console.log('cart >>>>>>>>', goAttributeValue)
                    // navigate(goAttributeValue)
                    document.getElementById('openCart').click();
                }
                if ( goAttributeValue === 'wishlist' ) {
                    // console.log('wishlist >>>>>>>>', goAttributeValue)
                    navigate('/wishlist/')
                    // document.getElementById('openCart').click();
                }

                if ( goAttributeValue === 'login' ) {
                    // console.log('login >>>>>>>>', goAttributeValue)
                    // navigate('/wishlist/')
                    document.getElementById('openAccountActive').click();
                }


            } else {
                const popInform = document.getElementById('popInform');
                popInform.classList.remove('active');


            }
        }
    }
    //
    // function closePopInform() {

    // }

    return (
        // <SmoothScroll>
        <div
            className={`body template-${templateClass} ${
                templateClass === 'page404' ? 'colorRevers' : ''
            }`}
        >
            <div id="scrollProgress" className="level1"></div>
            <GlobalStyles />
            <div className="container">
                <div className="wrapMain border-ver">
                    <Header colorRevers={templateClass === 'page404' ? 'colorRevers' : 'colorNormal'} />
                    <div className='main'>
                        {children}
                    </div>
                </div>
            </div>
            {templateClass === 'page404' ? null : (
                <Footer isFrontPage={getDataPageContext?.isFrontPage} />
            )}

            <div id='popInform' className='popInform anim' type=''>
                <div className="container">
                    <div className="wrap row row-0">
                        <div className="col-12 col-lg-4">
                            <div id='popInformTitle' className='title st3'> </div>
                        </div>
                        <div className="col-sm">
                            <div className='text st5' id='popInformText'>

                            </div>
                        </div>
                        <div className="col-sm-auto d-flex align-items-center">
                            <div className="wrapBtn">
                                    <span id='popInformGo' go='' onClick={()=>closePopInform('go')} className="btn btn6">

                                    </span>
                            </div>
                            <span className="iconBG icon24 iconBG20" onClick={()=>closePopInform()} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
        // </SmoothScroll>
    );
}

export default Layout;