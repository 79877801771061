import React from "react";
import WrapPost from "../../../assets/styles/WrapPost";
import {Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

function GetPosts({post}) {
    // console.log('post >>>>>>', post);
    return (
        <WrapPost className='wrap-post'>
            <div className="row-cols-1 row-cols-sm-2 row-cols-lg-3 row">
                {post?.map((item , index) => (
                    <div key={`post_` + item.id + `_` + index} className="col">
                        <div className="itemPost h100 border-hor-bottom"> 
                            <Link to={item.link} className="WrapImg">
                                {
                                    item.featuredImage ? (
                                        // <img className='img' alt={`${item.title} — Molfar`} title={item.title} src={item.featuredImage?.node.localFile?.publicURL} />
                                        <GatsbyImage alt="" className='img' image={ getImage(item.featuredImage?.node?.localFile) } />
                                    ) : (
                                        <span className="logo" />
                                    )
                                }

                            </Link>
                            <div className='wrap-text'>
                                <div className="WrapDate d-flex align-items-center">
                                    <div className="date st5 fw-3">{item.date}</div>
                                    {item.categories?.nodes.map((cat_item, i) => (
                                        <Link key={`post_category` + item.id + `_` + index + `_` + i} className='st5 PostCategory fw-3'
                                              to={cat_item.link}>{cat_item.name}</Link>
                                    ))}
                                </div>
                                <Link to={item.link} className="PostTitle">
                                    <h3 className='fw-4'>{item.title}</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </WrapPost>
    )
}

export default GetPosts;