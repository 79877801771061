// src/components/Footer/FooterSubscribeForm.js
import React, { useState } from "react";
import SendDateN from "../../function/sendDateN";

const FooterSubscribeForm = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [validMessage, setValidMessage] = useState("");
    const [inputDisable, setInputDisable] = useState(false);

    // Функція валідації email
    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    // Обробник сабміту форми
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setErrorMessage("Будь ласка, введіть коректну електронну адресу.");
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
            return;
        }
        setInputDisable(true);
        const formData = { email };
        const dataAll = {
            type: "subscribeEmail",
            data: { ...formData },
        };

        try {
            const response = await SendDateN(dataAll);
            console.log('response >>>', response)
            if (response.success) {
                setValidMessage('Дякую! Ви підписалися.');
                setEmail("");
            } else {
                setValidMessage(response.message);
                setErrorMessage("<strong>Помилка:</strong> Не вдалося надіслати лист.");
            }
            setInputDisable(false);
            setTimeout(() => {
                setErrorMessage("");
                setValidMessage("");
            }, 3000);
        } catch (err) {
            setInputDisable(false);
            setErrorMessage("<strong>Помилка:</strong> Не вдалося надіслати лист.");
            setTimeout(() => {
                setErrorMessage("");
                setValidMessage("");
            }, 3000);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={`wrap-input searchBox black ${errorMessage && 'error'}`}>
                <input
                    placeholder="your@email.com"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={inputDisable}
                />
                <button type="submit" className="btn btn2 btnIcon">
                    <div className="iconBG icon24 iconBG32"></div>
                </button>
            </div>
            {errorMessage && (
                <div
                    className="error-message"
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                ></div>
            )}
            {validMessage && <div className="valid-message">{validMessage}</div>}
        </form>
    );
};

export default FooterSubscribeForm;
