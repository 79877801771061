// TitleBlock.js
import React from "react";
import {Link} from "gatsby";
import Star from "../../Product/Star";

const TitleBlock = ({type, title, more, moreLink, sectionIndex, icon, averageRating, getToggleProps, isExpanded }) => {
  return (
      <div
          {...getToggleProps}
           className={`${isExpanded ? 'open' : 'close'} wrapTitleBlock sectionIndex_${sectionIndex} ${type === '1' ? 'border-ver' : '' }`
      }>
          {
              type === '5' ? (
                  <>
                      <div className="row row-0 border-hor-top d-flex">
                          <div className="col d-flex align-items-center justify-content-between">
                              <h2 className='BlockTitle min fw-4 d-flex align-items-center'>
                                  {icon ? (<span className={`iconBG icon24 ${icon} `}></span>) : ''}
                                  <span className='st1 ttu'>{title}</span>
                              </h2>
                          </div>
                          <div className="col-auto d-flex align-items-center">
                              <div style={{'opacity': `0`, 'visibility' : `hidden`}} className="wrapHeaderIcon">
                                  <span className='btn btnIcon borderNone bgNone'>

                                  </span>
                              </div>
                          </div>
                      </div>
                  </>
              ) : (
                  <>
                      <div className={`row row-0 border-hor-top row-section d-flex ${type === '4' && ( `wrapContent` )}`}>
                          <div className="col d-flex align-items-center justify-content-between">
                              <h2 className='BlockTitle min fw-4 d-flex align-items-center'>
                                  {icon ? (<span className={`iconBG icon24 ${icon} `}></span>) : ''}
                                  {
                                      type === '4' ? (<span className='st1 ttu'>{title}</span>) : (title)
                                  }
                              </h2>
                              <div className='more d-none d-sm-flex'>
                                  {more}
                                  {averageRating >= '0' ? (<Star h='s1' averageRating={averageRating}/>) : ''}
                              </div>
                          </div>
                          {type === '3' ? (
                              <>
                                  <div className="col-auto d-flex align-items-center">
                                      <div className="wrapHeaderIcon">
                                          <span id={`next_slide_${sectionIndex}`} className={`btn btn1 btnIcon borderNone bgNone prev`}>
                                              <span className={`iconBG icon24 iconBG32 rotate`}></span>
                                          </span>
                                      </div>
                                  </div>
                                  <div className="col-auto d-flex align-items-center">
                                      <div className="wrapHeaderIcon">
                                          <span id={`prev_slide_${sectionIndex}`} className={`btn btn1 btnIcon borderNone bgNone next`}>
                                              <span className={`iconBG icon24 iconBG32`}></span>
                                          </span>
                                      </div>
                                  </div>
                              </>
                          ) : (
                              <>
                                  {type === '4' ? (
                                      <div className="col-auto d-flex align-items-center">
                                          <div className="wrapHeaderIcon">
                                              <div className={`btn btn1 btnIcon borderNone bgNone`}>
                                                  <span className={`iconBG icon24 iconBG31`}></span>
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      <div className="col-auto d-flex align-items-center">
                                          <div className="wrapHeaderIcon">
                                              <Link to={moreLink} className='btn btn1 btnIcon borderNone bgNone'>
                                                  <span className={`iconBG icon24 iconBG32  ${type === '1' ? 'iconWhite' : ''}`}></span>
                                              </Link>
                                          </div>
                                      </div>
                                  )}
                              </>
                          )}
                      </div>
                  </>
              )
          }
      </div>
  )
}
export default TitleBlock;