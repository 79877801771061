// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import {getToken, clearToken, saveToken, getFromLocal} from "../function/functions";

export const AuthContext = createContext({
    authToken: null,
    user: null,
    login: () => {},
    logout: () => {},
    updateUserData: () => {}
});

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // При завантаженні: перевіряємо localStorage
        const data = getToken(); // повертає обʼєкт з полями { token, email, user_nicename, ... }
        if (data && data.token) {
            setAuthToken(data.token);
            setUser({
                nicename: data.user_nicename,
                email: data.email,
                displayName: data.user_display_name,
                first_name: data.first_name,
                last_name: data.last_name,
                username: data.username,
                phone: data.phone,
                userId: data.userId,
                wishlist: data.wishlist || []
            });
        }
    }, []);

    const login = (data) => {
        // Зберігаємо все у localStorage (через saveToken) і в state
        saveToken(data);
        setAuthToken(data.token);
        setUser({
            nicename: data.user_nicename,
            email: data.email,
            displayName: data.user_display_name,
            first_name: data.first_name,
            last_name: data.last_name,
            username: data.username,
            phone: data.phone,
            userId: data.userId,
            wishlist: data.wishlist || getFromLocal('wishlist') || []
        });
    };

    const logout = () => {
        clearToken();
        setAuthToken(null);
        setUser(null);
    };

    //
    // ОНОВЛЕННЯ ДАНИХ КОРИСТУВАЧА
    //
    // const updateUserData = (newUserData) => {
    //     // newUserData - це обʼєкт, що містить поля, які ви хочете оновити, наприклад:
    //     // { email: 'новий email', first_name: '...', last_name: '...', phone: '...' }
    //
    //     // Оновлюємо user в state. Злимаємо старі поля з новими.
    //     setUser((prevUser) => {
    //         if (!prevUser) return null;
    //
    //         console.log('prevUser', prevUser)
    //
    //         const updatedUser = {
    //             ...prevUser,
    //             // Якщо якісь поля з newUserData відсутні, вони не перезапишуться
    //             email: newUserData.email || prevUser.email,
    //             first_name: newUserData.first_name || prevUser.first_name,
    //             last_name: newUserData.last_name || prevUser.last_name,
    //             phone: newUserData.phone || prevUser.phone
    //             // тощо, якщо потрібно
    //         };
    //
    //         // Паралельно оновимо localStorage, щоб зберегти зміни між перезавантаженнями
    //         const tokenData = {
    //             // дані, що вже є в сховищі
    //             token: authToken,
    //             user_nicename: updatedUser.nicename,
    //             user_display_name: updatedUser.displayName,
    //             first_name: updatedUser.first_name,
    //             last_name: updatedUser.last_name,
    //             email: updatedUser.email,
    //             username: updatedUser.username,
    //             phone: updatedUser.phone,
    //             userId: updatedUser.userId
    //         };
    //         saveToken(tokenData);
    //         return updatedUser;
    //     });
    // };


    const updateUserData = (newUserData) => {
        setUser((prevUser) => {
            if (!prevUser) return null;

            // console.log('prevUser', prevUser)


            // Об'єднуємо старі дані користувача з новими динамічно
            const updatedUser = {
                ...prevUser,
                ...newUserData
            };

            // Оновлюємо localStorage
            const tokenData = {
                token: authToken,
                user_nicename: updatedUser.nicename,
                user_display_name: updatedUser.displayName,
                username: updatedUser.username,
                userId: updatedUser.userId,
                wishlist: updatedUser.wishlist,
                ...updatedUser // додаткові динамічні поля
            };

            saveToken(tokenData);

            // console.log('updatedUser', updatedUser)

            return updatedUser;
        });
    };


    return (
        <AuthContext.Provider value={{ authToken, user, login, logout, updateUserData }}>
            {children}
        </AuthContext.Provider>
    );
};
