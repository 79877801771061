import React from "react";
import TitleBlock from "../Constructor/TitleBlock/TitleBlock";
import GetPosts from "../Constructor/GetPosts/GetPosts";
import {Link} from "gatsby";

const FrontPageGetPost = ({post, footerPosts}) => {

    const postLimit = footerPosts?.count || 12;
    const limitedPosts = post?.nodes.slice(0, postLimit);
    // console.log('>>>>>>>>>>>>>>>>>', footerPosts)

  return (
      <>
          <TitleBlock type='1' title={footerPosts?.link?.title} more="БІЛЬШЕ" moreLink={footerPosts?.link?.url} />
          <GetPosts post={limitedPosts} />
          <div className="col WrapMobileBtn d-sm-none border-hor-bottom">
              <Link className='a btn btn1 borderNone w100 d-flex align-items-center justify-content-center fw-4' to={footerPosts?.link?.url}>
                  На сторінку новин <span className='iconBG icon24 iconBG31'></span>
              </Link>
          </div>
      </>
  )
}
export default FrontPageGetPost;