import React from "react";
import WrapStar from '../../assets/styles/Star'
const Star = ({h, averageRating}) => {
    // console.log('averageRating', averageRating);
    const starResult = averageRating === 0 ? 0 : ((averageRating * 100)/5);

  return (
      <WrapStar className="wrapStar mb-8 d-flex align-items-center  mt-auto">
          <div className={h}>
              <span style={{width: `${starResult}%`}}></span>
          </div>
          {
              h === 's2' ? (
                  <span className='st3'>{averageRating}</span>
              ) : (
                  <span className='tc3 st4'>({averageRating})</span>
              )
          }
      </WrapStar>
  )
}
export default Star;